export const isLocalhost = window.location.hostname === 'localhost';

export const BASE_URL = isLocalhost ? 'http://localhost:5000/api' : 'https://api.rentinstant.ca/api';

export const adminEmails = ['bandibannu773@gmail.com', 'suryakallena@gmail.com', 'kalyan.ch41@gmail.com'];

export const isProduction = window.location.hostname === 'rentinstant.ca';

// ---------------------------------- Stripe Constants ----------------------------------
export const STRIPE_PUBLIC_KEY = isLocalhost 
    ? "pk_test_51PrYCSRtI1XI1bZPcKYlBlt4Cd7KfDgzSrrFw53MTDIRBeaz6GYdj1GBawmIgzjiDyedVPMTKW2qEZLMnxb58aRW00H8hHDIgP" 
    : 'pk_live_51PrYCSRtI1XI1bZPglTQXqjqRUGizEx8nvsjIadsALlfzZ6NkKGVDnUpIyhS91IWghPbHn6G4s2oMdtSXsxyhaio00gPbGhcMx';

export const Currency = 'inr';
