import React, { useState, useEffect } from 'react';
import { Box, Typography, styled, Paper, InputLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth0 } from '@auth0/auth0-react';
import InfoIcon from '@mui/icons-material/Info';
import { addEllipsis } from '../../utils/util';
import {
    GetCountries,
    GetState,
    GetCity
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import { navData, times } from '../../constant/data';
import { Form, Input, Upload, Button, Row, Col, Card, Select, Cascader, Image, Modal, notification, Descriptions, DatePicker, Tag, Spin } from 'antd';
import { MdOutlineEdit } from "react-icons/md";
import axiosInstance from '../../AxiosInsstance';
import AddLocation from '../../Map/AddLocation';
import { useDispatch } from 'react-redux';
import { fetchCartItems } from '../../redux/actions/cartActions';
import { setShowVerifyUserLogin } from '../../redux/verifyUserLogin';
const { Option } = Select;

const Component = styled(Box)`
    border-top: 1px solid #f0f0f0;
    border-radius: 0px;
    display: flex;
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const LeftComponent = styled(Box)`
    margin: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
 
`;

const CartItem = ({ item }) => {
    const [open, setOpen] = useState(false);
    const categories = navData.map(item => item.text);
    const { user } = useAuth0();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [showRulesInput, setShowRulesInput] = useState(false);
    const [rule, setRule] = useState('');
    const [startDateTime, setStartDateTime] = useState(dayjs(item.startdatetime));
    const [endDateTime, setEndDateTime] = useState(dayjs(item.enddatetime));
    const [selectedTimes, setSelectedTimes] = useState(item.times);
    const { getAccessTokenSilently } = useAuth0();
    const [location, setLocation] = useState({
        lng: 0,
        lat: 0,
        town: '',
        country: '',
        state: '',
        address: '',
        postalcode: ''
    });

    const [product, setProduct] = useState({
        title: {
            shortTitle: item.title.shortTitle,
            longTitle: item.title.longTitle
        },
        category: item.category,
        price: item.price * 24,
        quantity: item.quantity,
        description: item.description,
        tagline: item.tagline,
        images: [],
        address: item.address,
        rules: item.rules,
        country: item?.country,
        state: item?.state,
        postalcode : item?.postalcode
    });
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!item?.url) {
            return;
        }
        const loadInitialFiles = async () => {
            try {
                const files = await Promise.all(
                    item.url.map(async (imageUrl, index) => {
                        const response = await fetch(`${imageUrl}`);
                        const blob = await response.blob();
                        const fileName = imageUrl.split('/').pop();
                        return new File([blob], fileName, { type: blob.type });
                    })
                );
                setFileList(files.map((file, index) => ({
                    uid: index,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file
                })));
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'There was an error loading the initial files.',
                });
            }
        };
    
        loadInitialFiles();
    }, [item]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRuleChange = (event) => {
        setRule(event.target.value);
    };

    const handleTimeClick = (time) => {
        setSelectedTimes((prevSelectedTimes) => {
            if (prevSelectedTimes.includes(time)) {
                return prevSelectedTimes.filter((t) => t !== time);
            } else {
                return [...prevSelectedTimes, time];
            }
        });
    }

    const handleRuleSubmit = () => {
        setProduct(prevState => ({
            ...prevState,
            rules: [...prevState.rules, rule]
        }));
        setRule('');
        setShowRulesInput(false);
    };

    const handleAddRule = () => {
        setShowRulesInput(true);
    };



    const handleImageChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url ? `${file.url}` : file.preview);
        setPreviewOpen(true);
    };


    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleChange = (name, value) => {
        if (name === "shortTitle" || name === "longTitle") {
            setProduct(prevState => ({
                ...prevState,
                title: {
                    ...prevState.title,
                    [name]: value
                }
            }));
        } else if (name === "quantity" && value < 0) {
            toast.error('Quantity cannot be negative');
        } else if (name === "address") {
            setLocation(prevState => ({
                ...prevState,
                address: value
            }));
        } 
        else if(name === "postalcode") {
            setLocation(prevState => ({
                ...prevState,
                postalcode: value
            }));
        }
    
        else {
            setProduct(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const items = [
        { key: '0', label: 'Title', children: addEllipsis(item.title.longTitle, 40), span: 3 },
        { key: '1', label: 'Location', children: item.location },
        { key: '2', label: 'Address', children: item.address },
        { key: '3', label: 'Quantity', children: item.quantity },
        { key: '4', label: 'Price', children: `₹${item.price * 24}` },
        { key: '5', label: 'Review Status', children: item.status },
        { key: '6', label: 'Product Status', children: item.liststatus },
    ];



    const handleSubmit = async (values) => {
        if (!endDateTime) {
            
            notification.error({
                message: 'Error',
                description: 'Please select the end date and time',
            });
            return;
        }
        const formData = new FormData();
        formData.append("id", item.id);
        formData.append('shortTitle', values.shortTitle);
        formData.append('longTitle', values.longTitle);
        formData.append('category', product.category ? product.category.toLowerCase() : '');
        formData.append('price', values.price === 0 ? 0 : values.price / 24);
        formData.append('quantity', values.quantity);
        formData.append('description', values.description);
        product.rules.forEach((rule) => {
            formData.append('rules', rule);
        });
        formData.append('startdatetime', dayjs(startDateTime).toISOString());  
        formData.append('enddatetime', endDateTime ? dayjs(endDateTime).toISOString() : null);
        formData.append('tagline', values.tagline);
        formData.append('location', location.town);
        formData.append('address', location.address);
        formData.append("status", "review");
        formData.append("email", user.email);
        formData.append("country", location.country);
        formData.append("state", location.state);
        formData.append("longitute", location.lng);
        formData.append("latitude", location.lat);
        formData.append("productmodifiedtime", new Date().toISOString());
        formData.append("postalcode", values.postalcode);

        fileList.forEach((file) => {
            formData.append('images', file.originFileObj);
        });

        selectedTimes.forEach((time) => {
            formData.append('times', time);
        });
        


        setLoading(true);

        try {
            const response = await axiosInstance.put(`/updateproduct/${item.id}`, formData);
            setOpen(true);
            notification.success({
                message: 'Product Updated',
                description: 'Product has been updated successfully.',
            });

            const token = await getAccessTokenSilently();

            await dispatch(fetchCartItems(user.email, token));
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while updating the product.',
            });
        } finally {
            setLoading(false);
        }
        setOpen(true);
    };
    return (
        <Component>
            <LeftComponent>
                <img src={`${item.url?.[0]}`} style={{ height: 110, width: 110 }} />
                {/* <GroupButton /> */}
            </LeftComponent>
            <Box style={{ margin: 20 }}>

                <Descriptions title="Product Info" bordered items={items} size='small' />
                <Button style={{ position: "absolute", top: "10px", right: "20px", zIndex: 999 }} variant="contained" color="primary" onClick={handleOpen}><MdOutlineEdit /></Button>
                <Modal
                    title="Edit Product"
                    open={open}
                    onOk={handleClose}
                    onCancel={handleClose}
                    closeIcon={true}
                    maskClosable={false}
                    destroyOnClose={true}
                    width={"100%"}
                >
                    <Spin spinning={loading} tip="Updating product...">
                        <Box sx={{ flexGrow: 1, px: 3, py: 5 }}>
                            <Paper sx={{ maxWidth: 1800, margin: 'auto', p: 2 }}>
                                <Form form={form} initialValues={{
                                    shortTitle: product.title.shortTitle,
                                    longTitle: product.title.longTitle,
                                    category: product.category,
                                    price: product.price,
                                    quantity: product.quantity,
                                    description: product.description,
                                    tagline: product.tagline,
                                    location: location.town,
                                    address: location.address,
                                    rules: product.rules,
                                    postalcode : product.postalcode
                                }} onFinish={handleSubmit} layout='vertical' style={{ marginTop: "20px" }}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={24} md={12}>
                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                                Description
                                            </Typography>
                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable >

                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="shortTitle"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Short Name</span>
                                                        }
                                                        rules={[{ message: 'Please input the short title!' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="longTitle"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Long Name</span>
                                                        }
                                                        rules={[{ message: 'Please input the long title!' }]}
                                                    >
                                                        <Input

                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24}>

                                                    <Form.Item
                                                        name="description"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Description</span>
                                                        }
                                                    >
                                                        <TextArea
                                                            rows={8}
                                                            value={product.description}
                                                        />
                                                    </Form.Item>

                                                </Col>
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                                Product Images
                                            </Typography>
                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable >
                                                <Col xs={24}>

                                                    <Form.Item
                                                        name="images"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Images</span>
                                                        }
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                    >
                                                        <Upload
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={handleImageChange}
                                                            onPreview={handlePreview}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileList.length >= 8 ? null : uploadButton}
                                                        </Upload>
                                                        {previewImage && (
                                                            <Image
                                                                wrapperStyle={{
                                                                    display: 'none',
                                                                }}
                                                                preview={{
                                                                    visible: previewOpen,
                                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                                                }}
                                                                src={previewImage}
                                                            />
                                                        )}
                                                    </Form.Item>


                                                </Col>
                                            </Card>

                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px", marginTop: "20px" }}>
                                                Category
                                            </Typography>
                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                                <Col xs={24} sm={24} md={24}>
                                                    <div>
                                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Category</InputLabel>
                                                        <Select
                                                            value={product.category}
                                                            onChange={(value) => handleChange('category', value)}
                                                            name="category"
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Option value="" disabled>
                                                                Select Category
                                                            </Option>
                                                            {categories.map((category) => (
                                                                <Option key={category} value={category}>{category}</Option>
                                                            ))}
                                                        </Select>
                                                    </div>

                                                </Col>
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="tagline"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginTop: "10px" }}>Taglines</span>
                                                        }
                                                    >
                                                        <Input

                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Card>

                                        </Col>

                                        <Col xs={24} sm={24} md={12}>
                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                                Shipping and Delivery
                                            </Typography>
                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>

                                                <Col xs={24} sm={24} md={24}>
                                                    <AddLocation setLocation={setLocation} location={location} />
                                                </Col>
                                                <Col xs={24} sm={24} md={24}>
                                                    <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Country : {location.country}</InputLabel>
                                                    <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>State : {location.state}</InputLabel>
                                                    <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>City : {location.town}</InputLabel>

                                                    <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Address:</InputLabel>
                                                    <TextArea
                                                        value={location.address}
                                                        style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}
                                                        onChange={(e) => handleChange('address', e.target.value)}
                                                    />
                                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Postal Code:</InputLabel>
                                                        <Input value={location.postalcode} onChange={(e) => handleChange("postalcode" , e.target.value)  }  />
                                                    {/* <Cascader
                                                        defaultValue={[product?.country, selectedState, product.location]}
                                                        style={{ width: '100%', zIndex: 999 }}
                                                        className="my-cascader"
                                                        options={locationOptions}
                                                        loadData={loadData}
                                                        onChange={handleLocationChange}
                                                        placeholder="Location"
                                                    /> */}
                                                </Col>
                                                {/* <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="address"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Address</span>
                                                        }
                                                        style={{ marginTop: "10px" }}
                                                    >
                                                        <Input

                                                        />
                                                    </Form.Item>
                                                </Col> */}
                                            </Card>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                                Pricing
                                            </Typography>
                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>

                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="price"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Price/day</span>
                                                        }
                                                    >
                                                        <Input
                                                            type="number"

                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24}>
                                                    <Form.Item
                                                        name="quantity"
                                                        label={
                                                            <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Quantity</span>
                                                        }
                                                    >
                                                        <Input
                                                            type="number"

                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Card>

                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                                Period Date
                                            </Typography>

                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                                <Col xs={24} sm={24} md={24}>
                                                <div>
                                                <p style={{ fontWeight: 600 }}>Select Date Range:</p>
                                                <DatePicker.RangePicker
                                                    value={[
                                                        startDateTime ? dayjs(startDateTime).tz('Asia/Kolkata') : null,
                                                        endDateTime ? dayjs(endDateTime).tz('Asia/Kolkata') : null
                                                    ]}
                                                    onChange={(dates) => {
                                                        const [start, end] = dates || [null, null];
                                                        setStartDateTime(start ? dayjs(start).tz('Asia/Kolkata') : null);
                                                        setEndDateTime(end ? dayjs(end).tz('Asia/Kolkata') : null);
                                                    }}
                                                    format="DD/MM/YYYY"
                                                    style={{ width: '100%' }}
                                                    disabledDate={(current) => {
                                                        return current && current < dayjs().startOf('day');
                                                    }}
                                                />
                                            </div>
                                                    <p style={{ fontWeight: 600 }}>Select Avaliable Time : </p>
                                                    <div style={{ marginTop: '10px' }}>
                                                        {times.map((time) => (
                                                            <Tag
                                                                key={time}
                                                                onClick={() => handleTimeClick(time)}
                                                                style={{
                                                                    background: selectedTimes.includes(time) ? "black" : "white",
                                                                    color: selectedTimes.includes(time) ? "white" : "black",
                                                                    cursor: "pointer",
                                                                    marginBottom: "5px",
                                                                    padding: "5px 10px",
                                                                    transition: "background 0.3s, color 0.3s",
                                                                }}
                                                                onMouseEnter={(e) => {
                                                                    e.target.style.background = "gray";
                                                                    e.target.style.color = "white";
                                                                }}
                                                                onMouseLeave={(e) => {
                                                                    e.target.style.background = selectedTimes.includes(time) ? "black" : "white";
                                                                    e.target.style.color = selectedTimes.includes(time) ? "white" : "black";
                                                                }}
                                                            >
                                                                {time}
                                                            </Tag>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Card>

                                        </Col>




                                        <Col xs={24} sm={24} md={12} style={{ paddingBottom: "40px" }}>
                                            <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                                Defects
                                            </Typography>

                                            <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                                <Col xs={24} >

                                                    <Button variant='outlined' onClick={handleAddRule} style={{ marginBottom: "10px" }}>Add Rule</Button>

                                                    {showRulesInput && (

                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <Input
                                                                fullWidth
                                                                label={
                                                                    <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Rule</span>
                                                                }
                                                                name="rule"
                                                                value={rule}
                                                                onChange={handleRuleChange}

                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InfoIcon color="action" />
                                                                    ),
                                                                }}
                                                            />
                                                            <Button variant='contained' onClick={handleRuleSubmit}>Submit Rule</Button>
                                                        </div>

                                                    )}
                                                </Col>
                                                <Col xs={24}>
                                                    {
                                                        product.rules.length > 0 && product.rules.map((rule, index) => (
                                                            <>
                                                                {index === 0 &&

                                                                    <Typography style={{ color: "grey", fontWeight: "600", fontSize: "14px" }}>Rules</Typography>

                                                                }

                                                                <Typography style={{ paddingLeft: 10, color: "black", fontWeight: "400", fontSize: "12px" }}>{index + 1} . {rule}</Typography>

                                                            </>
                                                        ))
                                                    }
                                                </Col>
                                            </Card>
                                            <Col xs={24}>
                                                <Button 
                                                    type="primary" 
                                                    htmlType="submit" 
                                                    style={{ marginTop: '1rem', marginBottom: '1rem', position: "absolute", right: "0px" }}
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Updating...' : 'Update Product'}
                                                </Button>
                                            </Col>

                                        </Col>

                                    </Row>
                                    <ToastContainer />
                                </Form>

                            </Paper>
                        </Box>
                    </Spin>
                </Modal >
            </Box >
        </Component >
    );
};

export default CartItem;
