import React from "react";
import {
  Button,
  Table,
  Typography,
  Image,
  Row,
  Col,
  Card,
  Divider,
} from "antd";
import styled from "styled-components";

import dayjs from "dayjs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { BiDetail } from "react-icons/bi";
import { CgTag } from "react-icons/cg";
import { IoMdPricetag } from "react-icons/io";
import { IoIosTime } from "react-icons/io";
import { IoMdCube } from "react-icons/io";
import {
  DollarOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const ResponsiveContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Page2 = ({
  product,
  startDateTime,
  endDateTime,
  insurance,
  calculatePriceWithCommission,
  totalPriceofItem,
  insurancePrice,
}) => {
  const calculateCommission = () => {
    const totalPrice = totalPriceofItem;
    const commission = totalPrice * 0.05;
    return commission.toFixed(2);
  };

  const productDetailsData = [
    {
      icon: <BiDetail style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Item Name",
      value: `${product?.title?.shortTitle}`,
    },
    {
      icon: <CgTag style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Category",
      value: product?.category?.toUpperCase(),
    },
    {
      icon: <MdLocationOn style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Location",
      value: product?.location,
    },
    {
      icon: <AiOutlineHome style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Address",
      value: product?.address,
    },
    {
      icon: <IoMdCube style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Quantity",
      value: product?.quantity,
    },

  ];

  const priceDetailsData = [
    {
      icon: <FaRegCalendarAlt />,
      label: "Start Date/Time",
      value: dayjs(startDateTime).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      icon: <FaRegCalendarAlt />,
      label: "End Date/Time",
      value: dayjs(endDateTime).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      icon: <IoIosTime />,
      label: "How many hours",
      value: `${Math.abs(endDateTime - startDateTime) / 36e5} hours`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Price",
      value: `$${product?.price * 24} / day`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Net Price",
      value: `$${totalPriceofItem}`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Commission",
      value: `$${calculateCommission()}`,
    },

  ];
  if (insurance) {
    priceDetailsData.push({
      icon: <IoMdPricetag />,
      label: "Insurance",
      value: `$${insurancePrice}`,
    });
  }

  return (
    <ResponsiveContainer>
      {/* Product Details Section */}
      <Card style={{ width: "100%", padding : 0 }} >
        <Col >
          <div
            className="product-details"
            style={{
              padding: "25px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              display : "flex",
              flexDirection : "column",
              justifyContent : "center",
              alignItems : "center" 
            }}
          >
            <Image
              width={250}
              height={250}
              src={product?.url?.[0]}
              alt={product?.title?.shortTitle}
              style={{ borderRadius: "10px", objectFit: "cover" }}
            />
            <div style={{width : "100%"}}>
              <Title level={5}>Price Details</Title>
              <Table
              style={{ width: "100%", marginTop: "20px" }}
                size="small"
                pagination={false}
                dataSource={productDetailsData}
                columns={[
                  {
                    title: "Detail",
                    dataIndex: "label",
                    render: (text, record) => (
                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {record.icon} {text}
                      </div>
                    ),
                  },
                  { title: "Value", dataIndex: "value" },
                ]}
              />
            </div>
          </div>
        </Col>
      </Card>

      {/* Price Details Section */}
      <Card
        style={{
          width: "100%",
          padding  : 0,
          borderRadius: "12px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
        
      >
        {/* Vertical Heading and Subheading */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#AD1E24",
            marginBottom: "16px",
          }}
        >
          <Title level={3} style={{ fontWeight: "bold", margin: 0 }}>
            Product Details
          </Title>
          <Text
            style={{ fontSize: "18px", fontWeight: "500", color: "#AD1E24" }}
          >
            Specifications
          </Text>
        </div>

        <Divider style={{ borderColor: "#AD1E24" }} />
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Title level={4} style={{ marginBottom: "15px" }}>
              Price Details
            </Title>
            <Row gutter={[16, 16]}>
              {priceDetailsData.map((item, index) => (
                <Col xs={24} sm={12} md={8} key={index}>
                  <Card
                    hoverable
                    style={{
                      borderRadius: "12px",
                      backgroundColor: "#fdf6f6",
                      border: "1px solid #AD1E24",
                      transition: "transform 0.3s, box-shadow 0.3s",
                    }}
                    bodyStyle={{ padding: "16px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.02)";
                      e.currentTarget.style.boxShadow =
                        "0px 6px 20px rgba(0, 0, 0, 0.15)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 4px 12px rgba(0,0,0,0.1)";
                    }}
                  >
                    <Text
                      strong
                      style={{
                        fontSize: "16px",
                        color: "#AD1E24",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      {item.icon} {item.label}:
                    </Text>
                    <Text style={{ fontSize: "14px", color: "#555" }}>
                      <strong>{item.value}</strong>
                    </Text>
                  </Card>
                </Col>
              ))}
              <Col xs={24}>
                <Card
                  hoverable
                  style={{
                    borderRadius: "12px",
                    background: "linear-gradient(135deg, #AD1E24 0%, #8B0000 100%)",
                    border: "none",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    marginTop: "20px",
                  }}
                  bodyStyle={{
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}

                >
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px"
                  }}>
                    <DollarOutlined style={{
                      fontSize: "24px",
                      color: "#ffffff",
                      marginRight: "12px"
                    }} />
                    <Text strong style={{
                      fontSize: "24px",
                      color: "#ffffff",
                    }}>
                      TOTAL PRICE
                    </Text>
                  </div>
                  <div style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    padding: "0px 32px",
                    borderRadius: "8px",
                    backdropFilter: "blur(4px)",
                  }}>
                    <Text style={{
                      fontSize: "30px",
                      color: "#ffffff",
                      fontWeight: "bold",
                      fontFamily: "'Arial', sans-serif",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                    }}>
                      ${calculatePriceWithCommission().toFixed(2)}
                    </Text>
                  </div>
                  <div style={{
                    marginTop: "16px",
                    padding: "8px 16px",
                    background: "rgba(255, 255, 255, 0.9)",
                    borderRadius: "4px",
                    animation: "pulse 2s infinite"
                  }}>
                    <Text style={{
                      fontSize: "14px",
                      color: "#AD1E24",
                      fontWeight: "bold"
                    }}>
                      * Final price includes all fees and selected options
                    </Text>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </ResponsiveContainer>
  );
};

export default Page2;
