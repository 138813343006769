import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { Card, Input, Button, Typography, Spin } from 'antd';
import { AimOutlined, EnvironmentOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title } = Typography;
const libraries = ['places'];

const MapContainer = styled.div`
  height: 500px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin: 20px 0;
`;

const SearchContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 400px;

  .ant-input-affix-wrapper {
    border-radius: 50px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px 16px;
    transition: all 0.3s ease;
    
    &:hover, &:focus {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
    
    .ant-input {
      font-size: 16px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const LocationButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  right: 60px;
  z-index: 10;
  border-radius: 50px;
  height: 44px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: #AD1E24;
  border: none;
  box-shadow: 0 2px 8px rgba(173, 30, 36, 0.3);
  transition: all 0.3s ease;

  &:hover, &:focus {
    background: #8B1820;
    border: none;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(173, 30, 36, 0.4);
  }
  
  .anticon {
    font-size: 18px;
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
    height: 40px;
    padding: 0 16px;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);

  .ant-card-head {
    background: #AD1E24;
    border-bottom: none;
    padding: 16px 24px;
    
    .ant-card-head-title {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .ant-card-body {
    padding: 0;
  }
`;

const LocationInfo = styled.div`
  padding: 16px 24px;
  background: #f8f8f8;
  border-top: 1px solid #f0f0f0;

  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    
    .anticon {
    color: #AD1E24;
      margin-right: 8px;
    }
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const AddLocation = ({ setLocation, location }) => {
  const mapRef = useRef(null);
  const searchBoxRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [center, setCenter] = useState({
    lat: location.lat || 0,
    lng: location.lng || 0
  });

  const onLoad = useCallback((map) => {
    console.log('Map loaded');
    mapRef.current = map;
    setIsLoading(true);
    
    // Get initial location from IP if no location is set
    if (!location.lat && !location.lng) {
      fetch('https://ipapi.co/json')
        .then((response) => response.json())
        .then((data) => {
          const newCenter = {
            lat: data.latitude,
            lng: data.longitude
          };
          setCenter(newCenter);
          map.panTo(newCenter);
          setLocation({
            lat: data.latitude,
            lng: data.longitude,
            town: data.city || '',
            country: data.country_name || '',
            state: data.region || '',
            address: `${data.city}, ${data.region}, ${data.country_name}`,
            postalcode: data.postal || ''
          });
        })
        .catch((error) => {
          console.error('Error fetching IP location:', error);
        })
        .finally(() => {
          console.log('Loading finished');
          setIsLoading(false);
        });
    } else {
      console.log('Using existing location');
      setIsLoading(false);
    }
  }, [location.lat, location.lng, setLocation]);

  const fetchAddress = useCallback((lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    setIsLoading(true);
    
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          let town = '';
          let country = '';
          let state = '';
          let postalcode = '';
          let address = results[0].formatted_address;

          results[0].address_components.forEach((component) => {
            if (component.types.includes('locality')) {
              town = component.long_name;
            }
            if (component.types.includes('country')) {
              country = component.long_name;
            }
            if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            }
            if (component.types.includes('postal_code')) {
              postalcode = component.long_name;
            }
          });

          setLocation((prev) => ({
            ...prev,
            town,
            country,
            state,
            address,
            postalcode,
            lat,
            lng
          }));
        }
      }
      setIsLoading(false);
    });
  }, [setLocation]);

  const onMarkerDragEnd = useCallback((e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    fetchAddress(lat, lng);
  }, [fetchAddress]);

  const onSearchBoxLoad = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onPlacesChanged = useCallback(() => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      
      if (places && places.length > 0) {
        const place = places[0];
        const newLocation = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        
        setCenter(newLocation);
        mapRef.current?.panTo(newLocation);
        fetchAddress(newLocation.lat, newLocation.lng);
      }
    }
  }, [fetchAddress]);

  const handleGeolocation = useCallback(() => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          
          setCenter(newLocation);
          mapRef.current?.panTo(newLocation);
          fetchAddress(newLocation.lat, newLocation.lng);
        },
        (error) => {
          console.error('Geolocation error:', error);
          setIsLoading(false);
        }
      );
    }
  }, [fetchAddress]);

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  return (
    <StyledCard
      title="Select Location"
      extra={<EnvironmentOutlined style={{ color: 'white', fontSize: '24px' }} />}
    >
      <LoadScript
        googleMapsApiKey="AIzaSyCxjbg_Um1VgUfdI5G-SSNfdZ836A9FIq0"
        libraries={libraries}
      >
        <MapContainer>
          {isLoading && (
            <LoadingContainer>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#AD1E24' }} />} />
            </LoadingContainer>
          )}

          <SearchContainer>
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <Input
                prefix={<EnvironmentOutlined style={{ color: '#AD1E24' }} />}
                placeholder="Search for a location..."
                allowClear
                size="large"
              />
            </StandaloneSearchBox>
          </SearchContainer>
          
          <LocationButton 
            type="primary"
            icon={<AimOutlined />}
            onClick={handleGeolocation}
            size="large"
          >
            Use Current Location
          </LocationButton>

          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
            center={center}
            zoom={13}
            onLoad={onLoad}
            options={mapOptions}
          >
            {location.lat && location.lng && (
              <Marker
                position={{ lat: location.lat, lng: location.lng }}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </MapContainer>

        {location.address && (
          <LocationInfo>
            <p>
              <EnvironmentOutlined />
              {location.address}
            </p>
          </LocationInfo>
        )}
      </LoadScript>
    </StyledCard>
  );
};

export default AddLocation;