import { Box, Checkbox, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import "../../assets/Styles/style.css";

import { BiDetail } from "react-icons/bi"; // For item name

import { Card, Descriptions, Divider, notification, Typography } from "antd";
import { DatePicker, Select } from "antd";
import axiosInstance from "../../AxiosInsstance";
import { Row, Col } from "antd";
// Importing from react-icons for demonstration

import {
  TagOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  AppstoreAddOutlined,
  ClockCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons"; // Importing Ant Design icons

const { RangePicker } = DatePicker;


const StyledOuterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "#ffffff",
  padding: "20px",
  flexDirection: "row",
  gap: "40px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    padding: "0px",
  },
}));

const RangeFields = styled(Box)(({ theme }) => ({
  width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    
 
  [theme.breakpoints.down("md")]: {
    width: "100%",
    borderRight: "none",
    BorderBottom: "1px solid #e0e0e0",
  },
}));

const ProductDetails = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "auto",
  },
}));

const { Title, Text } = Typography;

const Page1 = ({
  product,
  startDateTime,
  endDateTime,
  setStartDateTime,
  setEndDateTime,
  insurance,
  setInsurance,
  calculatePriceWithCommission,
  totalPriceofItem,
  insurancePrice,
}) => {
  const [dateRanges, setDateRanges] = useState([]);

  useEffect(() => {
    if (product?.id) {
      axiosInstance
        .get(`/dates/${product.id}`)
        .then((response) => setDateRanges(response.data))
        .catch((error) => {
          notification.error({
            message: "Error",
            description: `Error fetching dates: ${error.message}`,
            placement: "topRight",
          });
        });
    }
  }, [product]);

  const calculateCommission = () => {
    const totalPrice = totalPriceofItem;
    const commission = totalPrice * 0.05; // 5% commission
    return commission.toFixed(2); // Round to 2 decimal places
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    if (current && current < dayjs().endOf("day")) {
      return true;
    }

    // Check if the current date is within any of the date ranges
    for (let range of dateRanges) {
      const start = dayjs(range.startDateTime);
      const end = dayjs(range.endDateTime).endOf("day"); // Extend to the end of the day
      if (current && current.isBetween(start, end, null, "[]")) {
        return true;
      }
    }

    return false;
  };

  const disabledTime = () => {
    const timesToDisable = product?.times || [];

    const parseTime = (time) => {
      const [hourMinute, period] = time.split(" ");
      let [hour, minute] = hourMinute.split(":").map(Number);
      if (period === "PM" && hour !== 12) hour += 12;
      if (period === "AM" && hour === 12) hour = 0;
      return { hour, minute };
    };

    const disabledHours = () => {
      const hours = Array.from({ length: 24 }, (_, i) => i);
      const disabledHours = timesToDisable.map((time) => parseTime(time).hour);
      return hours.filter((hour) => !disabledHours.includes(hour));
    };

    const disabledMinutes = () => {
      const minutes = Array.from({ length: 60 }, (_, i) => i);
      const disabledMinutes = timesToDisable.map(
        (time) => parseTime(time).minute
      );
      return minutes.filter((minute) => !disabledMinutes.includes(minute));
    };

    return {
      disabledHours,
      disabledMinutes,
    };
  };

  const maxDate = product?.enddatetime
    ? dayjs(product.enddatetime).startOf("day")
    : dayjs("2025-01-01").startOf("day");

  return (
   
      <StyledOuterBox>
      <Card
          style={{
            width: "100%",
            borderRadius: "12px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          {/* Vertical Heading and Subheading */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#AD1E24",
              marginBottom: "16px",
            }}
          >
            <Title level={3} style={{ fontWeight: "bold", margin: 0 }}>
              Product Details
            </Title>
            <Text
              style={{ fontSize: "18px", fontWeight: "500", color: "#AD1E24" }}
            >
              Specifications
            </Text>
          </div>

          <Divider style={{ borderColor: "#AD1E24" }} />

          <Row gutter={[16, 16]}>
            {/* Item Name */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <BiDetail style={{ marginRight: "8px", color: "#AD1E24" }} />{" "}
                  Item Name:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>{product?.title?.shortTitle}</strong>
                </Text>
              </Card>
            </Col>

            {/* Category */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <TagOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Category:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>{product?.category}</strong>
                </Text>
              </Card>
            </Col>

            {/* Location */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <EnvironmentOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Location:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>{product?.location}</strong>
                </Text>
              </Card>
            </Col>

            {/* Address */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <HomeOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Address:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>{product?.address}</strong>
                </Text>
              </Card>
            </Col>

            {/* Quantity */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <AppstoreAddOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Quantity:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>{product?.quantity}</strong>
                </Text>
              </Card>
            </Col>

            {/* Duration */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <ClockCircleOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Duration (Hours):
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>{endDateTime && startDateTime
                    ? ((endDateTime - startDateTime) / 36e5).toFixed(2)
                    : "0.00"}</strong>
                </Text>
              </Card>
            </Col>

            {/* Price Per Day */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <DollarOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Price Per Day:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>${(product?.price * 24).toFixed(2)}</strong>
                </Text>
              </Card>
            </Col>

            {/* Rent Price */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <DollarOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Rent Price:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>${totalPriceofItem.toFixed(2)}</strong>
                </Text>
              </Card>
            </Col>

            {/* Commission */}
            <Col xs={24} sm={12} md={8}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#fdf6f6",
                  border: "1px solid #AD1E24",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
                bodyStyle={{ padding: "16px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0px 4px 12px rgba(0,0,0,0.1)";
                }}
              >
                <Text
                  strong
                  style={{
                    fontSize: "16px",
                    color: "#AD1E24",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <DollarOutlined
                    style={{ marginRight: "8px", color: "#AD1E24" }}
                  />{" "}
                  Commission:
                </Text>
                <Text style={{ fontSize: "14px", color: "#555" }}>
                  <strong>${calculateCommission()}</strong>
                </Text>
              </Card>
            </Col>

            {/* Insurance (Conditional) */}
            {insurance && (
              <Col xs={24} sm={12} md={8}>
                <Card
                  hoverable
                  style={{
                    borderRadius: "12px",
                    backgroundColor: "#fdf6f6",
                    border: "1px solid #AD1E24",
                    transition: "transform 0.3s, box-shadow 0.3s",
                  }}
                  bodyStyle={{ padding: "16px" }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.02)";
                    e.currentTarget.style.boxShadow =
                      "0px 6px 20px rgba(0, 0, 0, 0.15)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow =
                      "0px 4px 12px rgba(0,0,0,0.1)";
                  }}
                >
                  <Text
                    strong
                    style={{
                      fontSize: "16px",
                      color: "#AD1E24",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <DollarOutlined
                      style={{ marginRight: "8px", color: "#AD1E24" }}
                    />{" "}
                    Insurance:
                </Text>
                  <Text style={{ fontSize: "14px", color: "#555" }}>
                    <strong>${insurancePrice.toFixed(2)}</strong>
                  </Text>
                </Card>
              </Col>
            )}

            {/* Total Price */}
            <Col xs={24}>
              <Card
                hoverable
                style={{
                  borderRadius: "12px",
                  background: "linear-gradient(135deg, #AD1E24 0%, #8B0000 100%)",
                  border: "none",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  marginTop: "20px",
                }}
                bodyStyle={{ 
                  padding: "24px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                
              >
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px"
                }}>
                  <DollarOutlined style={{ 
                    fontSize: "24px",
                    color: "#ffffff",
                    marginRight: "12px"
                  }} />
                  <Text strong style={{
                    fontSize: "24px",
                    color: "#ffffff",
                  }}>
                    TOTAL PRICE
                  </Text>
                </div>
                <div style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  padding: "0px 32px",
                  borderRadius: "8px",
                  backdropFilter: "blur(4px)",
                }}>
                  <Text style={{
                    fontSize: "30px",
                    color: "#ffffff",
                    fontWeight: "bold",
                    fontFamily: "'Arial', sans-serif",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}>
                    ${calculatePriceWithCommission().toFixed(2)}
                  </Text>
                </div>
                <div style={{
                  marginTop: "16px",
                  padding: "8px 16px",
                  background: "rgba(255, 255, 255, 0.9)",
                  borderRadius: "4px",
                  animation: "pulse 2s infinite"
                }}>
                  <Text style={{
                    fontSize: "14px",
                    color: "#AD1E24",
                    fontWeight: "bold"
                  }}>
                    * Final price includes all fees and selected options
                  </Text>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>
        <RangeFields
         
        >
          <Card style={{ marginTop: "10px" }}>
            <div
              style={{ color: "#AD1E24", fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}
            >
              <p style={{ marginTop: "10px 0" }}>Booking Details</p>
              <p style={{ fontSize: "14px", color: "#888", marginTop: "10px 0" }}>
                Please select your preferred rental duration
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <h4 style={{ color: "#AD1E24", fontWeight: "bold", marginTop: "10px 0" }}>
                Available Up To:{" "}
                {dayjs(product?.enddatetime).format("DD/MM/YYYY")}
              </h4>
              <span style={{ fontSize: "14px", color: "#777", marginTop: "10px 0" }}>
                Current Time: {dayjs().format("DD/MM/YYYY HH:mm")}
              </span>
            </div>

            <h4
              style={{ color: "#333", fontWeight: "bold", marginTop: "10px", margin: "10px 0" }}
            >
              Select Start Date/Time
            </h4>

            <RangePicker
              style={{ width: "100%", borderRadius: "8px", marginTop: "10px" }}
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              minDate={dayjs().startOf("day")}
              maxDate={maxDate}
              showTime={{
                defaultValue: [
                  dayjs("09:00", "HH:mm"),
                  dayjs("12:00", "HH:mm"),
                ],
                format: "HH:mm",
                disabledHours: () =>
                  Array.from({ length: 24 }, (_, i) => i).filter(
                    (hour) => ![9, 12].includes(hour)
                  ),
                disabledMinutes: () =>
                  Array.from({ length: 60 }, (_, i) => i).filter(
                    (minute) => minute !== 0
                  ),
                hideDisabledOptions: true,
              }}
              format="DD/MM/YYYY HH:mm:ss"
              defaultValue={[startDateTime, endDateTime]}
              onChange={(dates) => {
                if (dates) {
                  setStartDateTime(dates[0]);
                  setEndDateTime(dates[1]);
                } else {
                  setStartDateTime(null);
                  setEndDateTime(null);
                }
              }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Checkbox
                checked={insurance}
                onChange={(e) => setInsurance(e.target.checked)}
                size="small"
              />
              <p style={{ color: "#888", marginLeft: "10px", marginTop: "10px 0" }}>
                Add insurance for additional security?
              </p>
            </div>

            <div style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>
              <p style={{ marginTop: "10px 0" }}>Note:</p>
              <p style={{ marginTop: "10px 0" }}>
                Please review the selected dates carefully. Changes after
                confirmation may incur additional fees.
              </p>
            </div>
          </Card>
        </RangeFields>

        {/* Main Heading and Subheading Vertically Aligned */}

        
      </StyledOuterBox>
   
  );
};

export default Page1;
