import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./Components/default";
import { Layout, Modal, Form, Input } from "antd";


//components
import Header from "./Components/Header/Header";
import DetailView from "./Components/ItemDetails/DetailView";
import TemplateProvider from "./templates/TemplateProvider";
import Cart from "./Components/Cart/Cart";
import AddProduct from "./Components/Product/AddProduct";
import ViewAllProducts from "./Components/Home/ViewAllProducts";
import HorizontalLinearStepper from "./Components/Home/StepperTrial";
import Admin from "./Components/Admin/Admin";
import UserAdmin from "./Components/Admin/UserAdmin";
import MyOrder from "./Components/MyOrders/MyOrder";
import ProfilePage from "./Components/Admin/ProfilePage";
import "./assets/Styles/style.css";
import MyWallet from "./Components/MyWallet/MyWallet";
import ApplicationFooter from "./Components/Home/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import "./Stlye.scss";
import ProductList from "./Components/Trail/Trail";
import { useDispatch, useSelector } from "react-redux";
import { ClockLoader } from "react-spinners";
import { fetchUserByEmail, updateProfile } from "./redux/actions/userActions";
import ProfileUpdateModal from "./Components/Modals/ProfileUpdateModal";

const { Header: AntHeader, Footer: AntFooter, Content } = Layout;

function App() {
  const { getAccessTokenSilently, isAuthenticated, user, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("user_name", user?.email);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user?.email) {
        try {
          await dispatch(fetchUserByEmail(user.email));
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [dispatch, user]);

  useEffect(() => {
    if (userData?.error === "Request failed with status code 404") {
      form.setFieldsValue({
        firstname: user.given_name,
        lastname: user.family_name,
        email: user.email,
        avatar: user.picture,
        phonenumber: "",
      });
      setModalVisible(true);
    }
    else{
      setModalVisible(false);
    }
  }, [userData?.user, userData?.error]);

  if ((userData?.loading || isLoading)) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <ClockLoader speedMultiplier={3} size={75} />
      <h4>Loading...</h4>
    </div>;
  }


  return (
    <TemplateProvider>
      <BrowserRouter>
        <Layout>
          <AntHeader>
            <Header />
          </AntHeader>
          <Content style={{ padding: 0 }}>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/product/:id" element={<DetailView />} />
              <Route path="/addproduct" element={<AddProduct />} />
              <Route path="/category/:category" element={<ViewAllProducts />} />
              <Route path="/rental-pay/:id" element={<HorizontalLinearStepper />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/useradmin" element={<UserAdmin />} />
              <Route path="/myorders" element={<MyOrder />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/mywallet" element={<MyWallet />} />
              <Route path="/trail" element={<ProductList />} />
            </Routes>

          </Content>
          <AntFooter style={{ padding: 0 }}>
            <ApplicationFooter />
          </AntFooter>
          <ProfileUpdateModal 
            visible={modalVisible} 
            onClose={() => setModalVisible(false)} 
            userData={user} 
            form={form} 
          />
        </Layout>
      </BrowserRouter>
    </TemplateProvider>
  );
}

export default App;
