import React from 'react';
import { Modal, Timeline, Row, Col, Tag, Divider, Typography, Button } from 'antd';
import { ClockCircleOutlined, EnvironmentOutlined, UserOutlined, ShoppingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { NavigationOutlined, PhoneOutlined } from '@mui/icons-material';

const { Text } = Typography;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  padding: 16px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
`;

const ActionButton = styled(Button)`
  flex: 1;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.navigate-btn {
    background: #AD1E24;
    
    &:hover, &:focus {
      background: #8f181d;
    }
  }
  
  .anticon {
    font-size: 20px;
    margin-right: 8px;
  }
`;

const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    background: #AD1E24;
    border-radius: 12px 12px 0 0;
    padding: 20px 24px;
    border-bottom: none;
  }
  
  .ant-modal-title {
    color: white !important;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .ant-modal-close-x {
    color: white;
    font-size: 20px;
  }

  .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(173, 30, 36, 0.15);
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 24px;
    background: #fafafa;
  }

  .ant-modal-close-x {
    background: #ff4d4f;
    color: black;
    border-radius: 4px;
  }

  .ant-modal-close:hover {
    background: #d93d3d;
  }
`;

const SectionCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-left: 4px solid ${props => props.borderColor || '#AD1E24'};
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(173, 30, 36, 0.1);
    transform: translateY(-2px);
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
  .anticon {
    font-size: 20px;
    color: #AD1E24;
    margin-right: 12px;
  }
`;

const SectionTitle = styled.h3`
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 600;
`;

const Label = styled(Text)`
  color: #666;
  font-size: 14px;
  margin-right: 8px;
`;

const Value = styled(Text)`
  color: ${props => props.important ? '#AD1E24' : '#333'};
  font-weight: ${props => props.important ? '600' : 'normal'};
  font-size: ${props => props.important ? '15px' : '14px'};
`;

const StyledTag = styled(Tag)`
  border-radius: 4px;
  padding: 4px 12px;
  font-weight: 600;
  border: none;
  background: ${props => props.color || '#AD1E24'};
  color: white;
  margin-left: 8px;
`;

const StyledTimeline = styled(Timeline)`
  margin-top: 16px;
  
  .ant-timeline-item-tail {
    border-left: 2px solid #ffe7e7;
  }
  
  .ant-timeline-item-head {
    background: #AD1E24;
    border-color: #AD1E24;
  }
  
  .ant-timeline-item-content {
    padding-bottom: 24px;
  }
`;

const OrderDetailsModal = ({ visible, onClose, item }) => {
  if (!item) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getStatusColor = (status) => {
    const statusColors = {
      'active': '#52c41a',
      'pending': '#faad14',
      'completed': '#1890ff',
      'cancelled': '#ff4d4f'
    };
    return statusColors[status?.toLowerCase()] || '#AD1E24';
  };

  const handleNavigate = () => {
    const { latitude, longitute } = item;
    // Open in Google Maps
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitute}`, '_blank');
  };

  const handleCall = () => {
    window.location.href = `tel:${item.ownerphone}`;
  };


  return (
    <ModalWrapper
      title="Rental Details"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <SectionCard>
        <SectionHeader>
          <UserOutlined />
          <SectionTitle>User Information</SectionTitle>
        </SectionHeader>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Label>Rented By:</Label>
            <Value important>{item.user_email}</Value>
          </Col>
          <Col span={12}>
            <Label>Provider:</Label>
            <Value important>{item.productby}</Value>
          </Col>
        </Row>
      </SectionCard>

      <SectionCard borderColor="#1890ff">
        <SectionHeader>
          <ShoppingOutlined />
          <SectionTitle>Product Details</SectionTitle>
        </SectionHeader>
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Label>Title:</Label>
            <Value important>{item.title?.longTitle || item.title?.shortTitle}</Value>
          </Col>
          <Col span={24}>
            <Label>Price:</Label>
            <Value important>₹{(item.amount).toLocaleString()}</Value>
            <StyledTag>Premium</StyledTag>
          </Col>
        </Row>
      </SectionCard>

      <SectionCard borderColor="#52c41a">
        <SectionHeader>
          <ClockCircleOutlined />
          <SectionTitle>Rental Duration</SectionTitle>
        </SectionHeader>
        <StyledTimeline>
          <Timeline.Item>
            <Label>Start Date:</Label>
            <Value important>{formatDate(item.dateRanges?.startDateTime)}</Value>
          </Timeline.Item>
          <Timeline.Item>
            <Label>End Date:</Label>
            <Value important>{formatDate(item.dateRanges?.endDateTime)}</Value>
          </Timeline.Item>
        </StyledTimeline>
      </SectionCard>

      <SectionCard borderColor="#faad14">
        <SectionHeader>
          <EnvironmentOutlined />
          <SectionTitle>Location Details</SectionTitle>
        </SectionHeader>
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Label>Address:</Label>
            <Value important>{item.address}</Value>
          </Col>
          <Col span={12}>
            <Label>Location:</Label>
            <Value>{item.location}</Value>
          </Col>
          <Col span={12}>
            <Label>State:</Label>
            <Value>{item.state}</Value>
          </Col>
          <Col span={12}>
            <Label>Country:</Label>
            <Value>{item.country}</Value>
          </Col>
          <Col span={12}>
            <Label>Coordinates:</Label>
            <Value>
              {item.latitude}, {item.longitute}
            </Value>
          </Col>
        </Row>
      </SectionCard>

      <SectionCard borderColor="#722ed1">
        <SectionHeader>
          <InfoCircleOutlined />
          <SectionTitle>Status Information</SectionTitle>
        </SectionHeader>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Label>Status:</Label>
            <StyledTag color={getStatusColor(item.status)}>
              {item.status}
            </StyledTag>
          </Col>
          <Col span={12}>
            <Label>Pickup Status:</Label>
            <StyledTag color={getStatusColor(item.pickup_status)}>
              {item.pickup_status}
            </StyledTag>
          </Col>
          <Col span={24}>
            <Divider style={{ margin: '12px 0' }} />
            <Label>Transaction Type:</Label>
            <Value>{item.transaction_type}</Value>
          </Col>
        </Row>
      </SectionCard>
      <ButtonGroup>
        <ActionButton
          type="primary"
          icon={<NavigationOutlined />}
          className="navigate-btn"
          onClick={handleNavigate}
        >
          Navigate
        </ActionButton>
        <ActionButton
          type="default"
          icon={<PhoneOutlined />}
          onClick={handleCall}
        >
          Call Owner
        </ActionButton>
      </ButtonGroup>
    </ModalWrapper>
  );
};

export default OrderDetailsModal;