import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ChevronLeft, ArrowUpRight, ArrowDownRight, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axiosInstance from '../../AxiosInsstance';
import { useMediaQuery } from '@mui/material';

const Container = styled.div`
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 20px;
  
  @media (min-width: 768px) {
    padding: 40px;
  }
  @media (max-width: 960px) {
    margin-top: 80px;
    }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled.div`
  background: white;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #64748b;
  cursor: pointer;
  padding: 8px;
  transition: color 0.2s;

  &:hover {
    color: #334155;
  }
`;

const WithdrawButton = styled.button`
  background: #2563eb;
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: #1d4ed8;
  }
`;

const BalanceGrid = styled.div`
  display: grid;
  gap: 24px;
  margin-bottom: 32px;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const BalanceCard = styled.div`
  padding: 24px;
  border-radius: 16px;
  color: white;
  background: ${props => props.$type === 'balance' ? 
    'linear-gradient(135deg, #2563eb, #1d4ed8)' : 
    'linear-gradient(135deg, #22c55e, #16a34a)'};
`;

const BalanceLabel = styled.p`
  opacity: 0.8;
  margin-bottom: 8px;
  font-size: 14px;
`;

const BalanceAmount = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin: 0;
`;

const TransactionList = styled.div`
  margin-top: 24px;
`;

const TransactionTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 16px;
`;

const TransactionItem = styled.div`
  background: #f8fafc;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 12px;
  transition: background-color 0.2s;

  &:hover {
    background: #f1f5f9;
  }
`;

const TransactionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TransactionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ProductImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
`;

const ProductDetails = styled.div`
  h4 {
    font-weight: 500;
    color: #1e293b;
    margin: 0 0 4px 0;
  }

  p {
    color: #64748b;
    font-size: 14px;
    margin: 0;
  }
`;

const TransactionAmount = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.$type === 'credit' ? '#16a34a' : '#dc2626'};
  font-weight: 600;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.5 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const Modal = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  max-width: 400px;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 16px 0;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  margin-bottom: 16px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #2563eb;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

const Button = styled.button`
  flex: 1;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;

  ${props => props.$primary ? `
    background: #2563eb;
    color: white;
    border: none;

    &:hover {
      background: #1d4ed8;
    }
  ` : `
    background: white;
    color: #64748b;
    border: 1px solid #e2e8f0;

    &:hover {
      background: #f8fafc;
    }
  `}
`;

const LoadingState = styled.div`
  text-align: center;
  padding: 32px;
  color: #64748b;
`;

const MyWallet = () => {
  const [transactions, setTransactions] = useState([]);
  const user = useAuth0();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const mainUser = useSelector(state => state.user.user);
  const itemsPerPage = 5;
  const navigate = useNavigate();
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up(960));
  

  const fetchTransactions = async (pageNumber) => {
    setLoading(true);
    try {
      const email = sessionStorage.getItem('user_name');
      const response = await axiosInstance.post(`/get-transaction-basedonid/${email}`, {
        pageNumber,
        pageSize: itemsPerPage,
      });
      setTransactions(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage, user]);

  const handleWithdraw = () => {
    const amount = parseFloat(withdrawAmount);
    if (isNaN(amount) || amount < 100) {
      alert('Minimum withdrawal amount is $100');
      return;
    }
    if (amount > mainUser.wallet) {
      alert('Insufficient balance');
      return;
    }
    setShowWithdrawModal(false);
    alert('Withdrawal request submitted');
  };

  const totalEarnings = transactions.reduce((sum, t) => 
    t.transaction_type === 'credit' ? sum + (parseFloat(t.amount, 10) ) : sum, 0
  );

  return (
    <Container>
      <Wrapper>
        <Card>
          <Header>
            <BackButton onClick={() => navigate('/')}>
              <ChevronLeft size={20} />
              <span style={{ marginLeft: '8px' }}>Back to Home</span>
            </BackButton>
            <WithdrawButton onClick={() => setShowWithdrawModal(true)}>
              Withdraw Funds
            </WithdrawButton>
          </Header>

          <BalanceGrid>
            <BalanceCard $type="balance">
              <BalanceLabel>Available Balance</BalanceLabel>
              <BalanceAmount>
                ${mainUser?.wallet ? (parseFloat(mainUser.wallet) ).toFixed(2) : '0.00'}
              </BalanceAmount>
            </BalanceCard>
            <BalanceCard $type="earnings">
              <BalanceLabel>Total Earnings</BalanceLabel>
              <BalanceAmount>${totalEarnings.toFixed(2)}</BalanceAmount>
            </BalanceCard>
          </BalanceGrid>

          <TransactionList>
            <TransactionTitle>Recent Transactions</TransactionTitle>
            {loading ? (
              <LoadingState>Loading...</LoadingState>
            ) : (
              transactions.map((transaction) => (
                <TransactionItem key={transaction.session_id}>
                  <TransactionContent>
                    <TransactionInfo>
                      {transaction.url && transaction.url.length > 0 && (
                        <ProductImage src={transaction.url[0]} alt="Product" />
                      )}
                      <ProductDetails>
                        <h4>{transaction.title?.shortTitle}</h4>
                        <p>{transaction.productby}</p>
                      </ProductDetails>
                    </TransactionInfo>
                    <TransactionAmount $type={transaction.transaction_type}>
                      {transaction.transaction_type === 'credit' ? (
                        <ArrowUpRight size={16} style={{ marginRight: '8px' }} />
                      ) : (
                        <ArrowDownRight size={16} style={{ marginRight: '8px' }} />
                      )}
                      ${(parseFloat(transaction.amount, 10) ).toFixed(2)}
                    </TransactionAmount>
                  </TransactionContent>
                </TransactionItem>
              ))
            )}

            <Pagination>
              <PaginationButton 
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
              >
                <ChevronLeft size={20} />
              </PaginationButton>
              <span>Page {currentPage}</span>
              <PaginationButton 
                onClick={() => setCurrentPage(p => p + 1)}
                disabled={transactions.length < itemsPerPage}
              >
                <ChevronRight size={20} />
              </PaginationButton>
            </Pagination>
          </TransactionList>
        </Card>
      </Wrapper>

      {showWithdrawModal && (
        <Modal>
          <ModalContent>
            <ModalTitle>Withdraw Funds</ModalTitle>
            <div style={{ marginBottom: '16px' }}>
              <BalanceLabel>Available Balance</BalanceLabel>
              <BalanceAmount style={{ color: '#1e293b' }}>
                ${mainUser?.wallet ? (parseFloat(mainUser.wallet) ).toFixed(2) : '0.00'}
              </BalanceAmount>
            </div>
            <Input
              type="number"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              placeholder="Enter amount to withdraw"
            />
            <ButtonGroup>
              <Button onClick={() => setShowWithdrawModal(false)}>
                Cancel
              </Button>
              <Button $primary onClick={handleWithdraw}>
                Withdraw
              </Button>
            </ButtonGroup>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default MyWallet;