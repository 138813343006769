import React, { useState } from 'react';
import { Typography, Drawer, Box, styled, TextField, Button, IconButton, Input, CircularProgress } from '@mui/material';
import { Row, Col, notification, Card } from 'antd';
import { ArrowLeftOutlined } from '@mui/icons-material';
import { FiEdit } from "react-icons/fi";
import { TbCoinFilled } from "react-icons/tb";
import { useSelector } from 'react-redux';

const EditProfileDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        width: 100%;
        padding: 10px;
        overflow-x: hidden;
        @media (min-width: 600px) {
            width: 95%;
            padding: 20px;
        }
    }
`;

const Avatar = styled(Box)`
    display: flex;
    flex-direction: column;
`;


const BackButtonWrapper = styled('div')({
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
});

const WalletContainer = styled('div')({
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    gap: '5px',
    fontSize: '12px',
    '@media (min-width: 600px)': {
        fontSize: '14px',
    },
    '@media (max-width: 400px)': {
        flexDirection: 'column',
        alignItems: 'center',
    }
});


const ProfileForm = ({ avatar, formValues, handleInputChange, handleAvatarChange, handleSave, handleBack, user, editProfileOpen, handleEditProfileClose, message, showSnackbar, handleSnackbarClose }) => {
    const initialEditMode = {
        firstname: !formValues.firstname,
        lastname: !formValues.lastname,
        city: !formValues.city,
        state: !formValues.state,
        phone: !formValues.phone,
        email: user.email,
        bio: !formValues.bio,
    };

    const mainUser = useSelector((state) => state.user.user);

    const {loading} = useSelector((state) => state.user);

    const [editMode, setEditMode] = useState(initialEditMode);
    const [isDirty, setIsDirty] = useState(false);
 

    const handleEditClick = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleChange = (e) => {
        handleInputChange(e);
        setIsDirty(true);
    };

    const openNotification = () => {
        notification.success({
            message: 'Success',
            description: message,
            placement: 'topLeft',
            onClose: handleSnackbarClose,
        });
    };

    return (
        <EditProfileDrawer
            anchor='right'
            open={editProfileOpen}
            onClose={handleEditProfileClose}
            disableBackdropTransition={false}
        >
            <div className='gradiant-1'></div>
            <div className='' />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                    <BackButtonWrapper onClick={handleBack}>
                        <ArrowLeftOutlined />
                    </BackButtonWrapper>
                    <div>
                        <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "10px", color: "grey" }}>
                            Back to Home
                        </Typography>
                        <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "14px", fontWeight: "600" }}>
                            My Profile
                        </Typography>
                    </div>
                </div>
                <div>
                    <WalletContainer>
                        <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "14px", fontWeight: "600", display : "flex" , justifyContent : "center" , alignItems : "center", gap : "5px" }}>
                           Wallet {" "} <TbCoinFilled size={14} />
                        </Typography>
                        <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "14px", fontWeight: "600"}}>
                            {mainUser?.wallet ? parseFloat(mainUser.wallet).toFixed(2) : '0.00'}
                        </Typography>
                    </WalletContainer>
                </div>
            </div>

            <Card style={{ 
                margin: 'auto', 
                padding: '10px',
                borderRadius: '15px',
                height: 'auto',
                minHeight: '80vh',
                display: "flex", 
                alignItems: "center",

                '@media (max-width: 600px)': {
                    padding: '5px',
                }
            }} >
                <Row gutter={[8, 8]} justify="center" align={'middle'}>
                    <Col xs={24} md={8} style={{ textAlign: 'center', padding: '10px' }}>
                        {
                            avatar && avatar !== "undefined" ? (
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', zIndex: 100 }}>
                                    <label htmlFor="avatar-upload">
                                        <img src={avatar} style={{ width: 100, height: 100, borderRadius: "100%" }} alt="" />
                                    </label>
                                    <div className='' onClick={() => document.getElementById('avatar-upload').click()}>
                                        <Input id="avatar-upload" type="file" onChange={handleAvatarChange} style={{ display: 'none' }} />
                                        <FiEdit />
                                    </div>
                                </div>
                            ) : (
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                    <label htmlFor="avatar-upload">
                                        <Avatar sx={{ bgcolor: "skyblue", width: 80, height: 80, borderRadius: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                            {user.name.charAt(0).toUpperCase()}
                                        </Avatar>
                                    </label>
                                    <div className='' onClick={() => document.getElementById('avatar-upload').click()}>
                                        <Input id="avatar-upload" type="file" onChange={handleAvatarChange} style={{ display: 'none' }} />
                                        <FiEdit />
                                    </div>
                                </div>
                            )
                        }
                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                            <h3 style={{ margin: '0 0 5px 0', fontSize: '1.2em', color: '#333', fontWeight: "600" }}>{user.name}</h3>
                            <p style={{ margin: 0, fontSize: '1em', color: '#666' }}>{user.email}</p>
                        </div>
                    </Col>
                    <Col xs={24} md={16} style={{ padding: '10px' }}>
                        <Row gutter={[8, 16]} justify="center" align={"middle"}>
                            {['firstname', 'lastname', 'city', 'state', 'phone', 'email', 'bio'].map((field) => (
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={field}>
                                    {editMode[field] ? (
                                        <TextField
                                            label={field.charAt(0).toUpperCase() + field.slice(1)}
                                            name={field}
                                            value={formValues[field]}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline={field === 'bio'}
                                            disabled={field === 'email'}
                                            size="small"
                                            sx={{ 
                                                marginBottom: '10px',
                                                '& .MuiInputBase-input': {
                                                    fontSize: '14px',
                                                }
                                            }}
                                        />
                                    ) : (
                                        <div style={{ 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            padding: '8px',
                                            backgroundColor: '#f5f5f5',
                                            borderRadius: '4px',
                                            marginBottom: '10px'
                                        }}>
                                            <Typography variant="body2" style={{ 
                                                flexGrow: 1,
                                                fontSize: '14px'
                                            }}>
                                                {formValues[field] || `Enter ${field}`}
                                            </Typography>
                                            <IconButton 
                                                onClick={() => handleEditClick(field)}
                                                size="small"
                                            >
                                                <FiEdit size={16} />
                                            </IconButton>
                                        </div>
                                    )}
                                </Col>
                            ))}
                            <Col xs={24} style={{ 
                                textAlign: 'center',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                                {loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={handleSave} 
                                        disabled={!isDirty}
                                        fullWidth
                                        sx={{
                                            maxWidth: '200px',
                                            height: '40px'
                                        }}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {showSnackbar && openNotification()}
            </Card>
        </EditProfileDrawer>
    );
};

export default ProfileForm;