import React, { useEffect } from 'react';
import { Box, CircularProgress, Divider, Skeleton, styled } from '@mui/material';
import NavBar from './Home/NarBar';
import Banner from './Home/Banner';
import Slide from './Home/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCartItems } from '../redux/actions/cartActions';
import { useAuth0 } from '@auth0/auth0-react';
import Cateogories from './Home/Cateogories';
import BannerSlide from './Home/BannerSilde';
import HomeScreenProducts from './Home/HomeScreenProducts';
import { notification, Spin } from 'antd';
import { ClockLoader } from 'react-spinners';
import { Hidden } from '@material-ui/core';
import { navData } from '../constant/data';

const Component = styled(Box)`
    padding: 20px 10px;
`;

const StyledDiv = styled('div')(({ theme }) => ({
    padding: '0px 40px',


    [theme.breakpoints.down('sm')]: {
        padding: '0px 5px',
    },
}));


const Home = () => {
    const getProducts = useSelector(state => state.getProducts);
    const { products, loading, error } = getProducts;
    
    return (
        <StyledDiv>
            <NavBar />
            <Divider />
            <Component>

                <Spin spinning={loading}>
                    <Banner />
                </Spin>

                <Hidden mdDown>
                    <Cateogories />
                </Hidden>
                <BannerSlide />

                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
                        <ClockLoader  speedMultiplier={3} size={75} />
                        <h4>Loading...</h4>
                    </div>
                ) : (
                    <>
                        {navData.map((category) => {
                            const filteredProducts = products.filter(item => item.category.toLowerCase() === category.text.toLowerCase() && item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
                            return filteredProducts.length > 0 && (
                                <Slide
                                    key={category.text}
                                    data={filteredProducts}
                                    title={category.text}
                                    timer={false}
                                    multi={true}
                                />
                            );
                        })}
                    </>
                )}
                <HomeScreenProducts products={products} />
            </Component>         
        </StyledDiv >
    );
};

export default Home;