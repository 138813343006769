export const navData = [
    { icon: 'GoGear ', text: 'Outdoor Gear', description: 'Gear for all your outdoor adventures', image : "./assets/category/caty1.jpg" ,badge: 'New'},
    { icon: 'MdSportsScore ', text: 'Sports', description: 'All your sports equipment needs', image : "./assets/category/caty2.jpg" ,badge: 'New'},
    { icon: 'LuPartyPopper ', text: 'Party & Events', description: 'Make your events memorable', image : "./assets/category/caty3.jpg" , badge: 'New'},
    { icon: 'LiaBicycleSolid ', text: 'Vehicles', description: 'Vehicles for every occasion', image : "./assets/category/caty4.jpg" , badge: 'New'},

    { icon: 'IoCameraOutline ', text: 'Electronics', description: 'Latest and greatest electronics', image : "./assets/category/caty5.jpg", badge: 'New'},    
    { icon: 'BsCupHot ', text: 'Home & Kitchen', description: 'Essentials for your home and kitchen', image : "./assets/category/caty6.jpg" , badge: 'New'},
    { icon: 'IoGameControllerOutline ', text: 'Toy & Gaming', description: 'Fun for everyone', image : "./assets/category/caty7.jpg", badge: 'New'},
    { icon: 'GiClothes ', text: 'Fashion', description: 'Stay in style', image : "./assets/category/caty8.jpg" , badge: 'New'},
    { icon: 'LiaToolsSolid ', text: 'Tools', description: 'Tools for every job', image : "./assets/category/caty9.jpg" , badge: 'New'},
    { icon: 'GiTrojanHorse ', text: 'Furniture', description: 'Furniture to make your house a home', image : "./assets/category/caty10.jpg", badge: 'New'},
    // { icon: 'GiSpookyHouse ', text: 'Garden & Lawn', description: 'Everything for your garden and lawn', image : "./assets/category/caty11.jpg", badge: 'New'},
];

export const bannerData = [
    { id: 1, url: './assets/1.jpg' },
    { id: 2, url: './assets/2.jpg' },
    { id: 3, url: './assets/3.jpg' }
]

export const Banneritem = [
    { id: 1, url: './assets/banner/banner11.jpg' },
    { id: 2, url: './assets/banner/banner22.jpg' },
    { id: 3, url: './assets/banner/banner33.jpg' }
]

export const dealData = [
    { 
        id: 'product1',
        url: 'https://rukminim1.flixcart.com/image/200/200/khf63680/cases-covers/back-cover/d/7/g/spigen-acs02256-original-imafxfgbffqaugur.jpeg?q=70', 
        detailUrl: '',
        title: {
            shortTitle: 'Mobile Covers'
        }, 
        discount: 'Extra 10% Off', 
        tagline: 'Deal of the day' 
    },
    { 
        id: 'product2',
        url: 'https://rukminim1.flixcart.com/image/200/200/k5lcvbk0/moisturizer-cream/9/w/g/600-body-lotion-aloe-hydration-for-normal-skin-nivea-lotion-original-imafz8jb3ftt8gf9.jpeg?q=70', 
        title: {
            shortTitle: 'Skin & Hair Care'
        },
        discount: 'From 99+5% Off', 
        tagline: 'Shampoos, Face Washes & More' 
    },
    { 
        id: 'product3',
        url: 'https://rukminim1.flixcart.com/flap/200/200/image/74bc985c62f19245.jpeg?q=70', 
        title: {
            shortTitle: 'Skybags & Safari'
        }, 
        discount: 'Upto 70% Off', 
        tagline: 'Deal of the Day' 
    },
    { 
        id: 'product4',
        url: 'https://rukminim1.flixcart.com/image/300/300/kll7bm80/smartwatch/c/1/n/43-mo-sw-sense-500-android-ios-molife-original-imagyzyycnpujyjh.jpeg?q=70', 
        detailUrl: 'https://rukminim1.flixcart.com/image/416/416/kll7bm80/smartwatch/c/1/n/43-mo-sw-sense-500-android-ios-molife-original-imagyzyycnpujyjh.jpeg?q=70',
        title: {
            shortTitle: 'Smart Watches',
            longTitle: 'Molife Sense 500 Smartwatch  (Black Strap, Freesize)',
        }, 
        price: {
            mrp: 6999,
            cost: 4049,
            discount: '42%'
        },
        description: 'The Molife Sense 500, a brilliant smartwatch with a beautiful large display. Say hello to the infinity 1.7-inch display with 2.5D curved edges. Thanks to seamless Bluetooth 5.0 connectivity, you wont have to keep waiting. Bring a change to your outfit every day with changeable straps. A splash of color every day keeps the boredom away.',
        discount: 'Grab Now', 
        tagline: 'Best Seller' 
    },
    { 
        id: 'product5',
        url: 'https://rukminim1.flixcart.com/flap/150/150/image/b616a7aa607d3be0.jpg?q=70', 
        title: {
            shortTitle: 'Sports & Fitness Essentials'
        }, 
        discount: 'Upto 80% Off', 
        tagline: 'Ab Exerciser, Yoga & more' 
    }
];

export const furnitureData = [
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/ke7ff680/hammock-swing/j/f/u/q3-jkaf-y3l0-furniture-kart-original-imafux96kpy7grch.jpeg?q=70', 
        title: {
            shortTitle: 'Hammock And Swings'
        }, 
        discount: 'From ₹199', 
        tagline: 'Trendy Collection' 
    },
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/bean-bag/h/v/b/f8-the-furniture-store-xxxl-original-imae65d3wg7qzpkn.jpeg?q=70', 
        title: {
            shortTitle: 'Bean Bag Covers'
        }, 
        discount: 'Min 80% Off', 
        tagline: 'XL, XXL & More' 
    },
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/jlcmavk0/aquarium-tank/s/4/5/usb-desktop-aquarium-with-running-water-calendar-temperature-and-original-imaf8hv4nkv55gx8.jpeg?q=70', 
        title: {
            shortTitle: 'Aquarium Tank'
        }, 
        discount: 'From ₹299', 
        tagline: 'Flat, Round, Cube & More' 
    },
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/jffpoy80/office-study-chair/v/v/z/pp-am-5001cb-apex-original-imaf3u8rbr5cdycv.jpeg?q=70', 
        title: {
            shortTitle: 'Office & Study Chairs'
        }, 
        discount: 'Min 50% Off', 
        tagline: 'Fabric & Leatherette' 
    },
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/jvcp9jk0/recliner/z/w/x/brown-top-grain-leather-sf7018011-1-royaloak-original-imafg9s9hh9vzpf3.jpeg?q=70', 
        title: {
            shortTitle: 'Recliner'
        }, 
        discount: 'From ₹4999', 
        tagline: 'Bantia, RoyalOak & More' 
    },
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/jlqwpe80-1/tv-entertainment-unit/d/t/f/particle-board-holland-tv-unit-black-forzza-black-original-imaf8t5ybywcdtys.jpeg?q=70', 
        title: {
            shortTitle: 'Tv Units'
        }, 
        discount: 'From ₹2100', 
        tagline: 'Forzza, Zuari & more' 
    },
    { 
        url: 'https://rukminim1.flixcart.com/image/300/300/inflatable-sofa/6/j/s/wsb031a-velvet-wds-original-imaeaphzbkgrz3xp.jpeg?q=70', 
        title: {
            shortTitle: 'Inflatable Sofas'
        }, 
        discount: 'Min 50% Off', 
        tagline: 'Furn Central & more' 
    },
];

export const times = [
    '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM', '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM',
    '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'
];