import { useEffect, useState } from 'react';
import { Typography, Drawer, Box, styled, List, ListItem, Divider, useMediaQuery } from '@mui/material';
import { Edit, PowerSettingsNew } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import ProfileForm from '../Drawers/EditProfile';
import { adminEmails } from '../../constants';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/actions/userActions';
import { GiTempleGate } from "react-icons/gi";
import { GrUserAdmin } from "react-icons/gr";

const Logout = styled(Typography)`
    font-size: 14px;
    margin-left: 20px;
`;

const Avatar = styled(Box)`
    display: flex;
    flex-direction: column;
`

const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        width: 280px;
        padding: 20px;
    }
`;

const StyledListItem = styled(ListItem)`
    padding: 10px 20px;
    cursor: pointer;
     &:hover {
        background-color: #f0f0f0; // Change this to your desired hover background color
    }
    
`;


const Profile = ({ref1}) => {
    const { user, logout } = useAuth0();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [editProfileOpen, setEditProfileOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [EditProfileLoading, setEditProfileLoading] = useState(false);
    const mainUser = useSelector(state => state.user.user);
    const isMdUp = useMediaQuery(theme => theme.breakpoints.down(960));

    const [formValues, setFormValues] = useState({
        firstname: '',
        lastname: '',
        city: '',
        state: '',
        bio: '',
        phone: '',
        email: user?.email,
    });
    const [avatar, setAvatar] = useState(user?.picture);
    const dispatch = useDispatch();
    // Add your admin emails here
    const isAdmin = adminEmails.includes(user.email);

    const handleAvatarChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setAvatar(e.target.result);

            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleInputChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        });
    };

    const handleEditProfileOpen = () => {
        setOpen(false); // Close the main drawer
        setEditProfileOpen(true); // Open the edit profile drawer
    };

    const handleEditProfileClose = () => {
        setEditProfileOpen(false);
    };

    const handleClick = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBack = () => {
        setEditProfileOpen(false); // Close the edit profile drawer
        setOpen(true); // Open the main drawer
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    useEffect(() => {
        if (mainUser) {
            setFormValues({
                firstname: mainUser.firstname,
                lastname: mainUser.lastname,
                city: mainUser.city,
                state: mainUser.state,
                bio: mainUser.bio,
                phone: mainUser.phone,
                email: user?.email,
            });

            setAvatar(mainUser?.avatar ? mainUser.avatar : user.picture);
        }
    }, [mainUser]);

    const handleSave = async () => {
        try {
            setEditProfileLoading(true);
            const formData = new FormData();
            Object.keys(formValues).forEach(key => {
                formData.append(key, formValues[key]);
            });

            formData.append('avatar', avatar);

            dispatch(updateProfile(formData));
            setEditProfileLoading(false);
        } catch (error) {
            setEditProfileLoading(false);
            notification.error({
                message: 'Error',
                description: `Error: ${error.message}`,
                placement: 'topRight',
            });
        }
    };




    return (
        <> {
            avatar && avatar !== "undefined"? (
                <div ref={ref1} style={{ position: 'relative', display: 'flex', justifyContent: 'center', zIndex: 100 }} onClick={handleClick}>
                    <label htmlFor="avatar-upload" style={{display : "flex", alignItems : "center"}}>
                        <img src={avatar} style={{ width: isMdUp ? 35 : 45, height: isMdUp ? 35 : 45, borderRadius: "100%", border: '1px solid rgba(173, 30, 36, 0.2)', boxShadow: '0 4px 8px rgba(173, 30, 36, 0.3)' }} alt="" />
                    </label>

                </div>
            ) : <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }} onClick={handleClick}>
                <label htmlFor="avatar-upload"  >
                    <Avatar sx={{ bgcolor: "skyblue", width: isMdUp ? 35 : 45, height: isMdUp ? 35 : 45, borderRadius: "100%", justifyContent: "center", display: "flex", alignItems: "center", border: '1px solid rgba(173, 30, 36, 0.2)', boxShadow: '0 4px 8px rgba(173, 30, 36, 0.3)' }}>
                        {user.name.charAt(0).toUpperCase()}
                    </Avatar>
                </label>

            </div>
        }
            <StyledDrawer
                anchor='right'
                open={open}
                onClose={handleClose}
                disableBackdropTransition={false}
            >
                <List style={{ justifyContent: "left" }}>

                  {/* -------------------------------  Admin Buttons -------------------------------------------- */}
                    {isAdmin && (
                        <>
                            <StyledListItem onClick={() => {
                                navigate("/admin")
                                handleClose()
                            }} style={{ padding: "10px 20px" }}>
                                <GiTempleGate size={19} style={{ color: "#AD1E24" }} />
                                <Logout >Product Admin</Logout>
                            </StyledListItem>
                            <Divider />
                            <StyledListItem style={{ padding: "10px 20px" }} onClick={() => {
                                navigate("/useradmin")
                                handleClose();
                            }}>
                                <GrUserAdmin style={{ color: "#AD1E24" }} size={19} />
                                <Logout >User Admin</Logout>
                            </StyledListItem>
                            <Divider />
                        </>)
                    }

                    <StyledListItem onClick={() => {
                        handleEditProfileOpen();
                    }} style={{ padding: "10px 20px" }}>
                        <Edit fontSize='small' style={{ color: "#AD1E24" }} />
                        <Logout >Edit Profile</Logout>
                    </StyledListItem>
                    <Divider />

                    <StyledListItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} style={{ padding: "10px 20px" }}>
                        <PowerSettingsNew fontSize='small' style={{ color: "#AD1E24" }} />
                        <Logout >Logout</Logout>
                    </StyledListItem>

                </List>
            </StyledDrawer >

            {/* ---------------------------------------------- profile Tab ------------------------------------------------ */}
            <ProfileForm
                avatar={avatar}
                formValues={formValues}
                handleInputChange={handleInputChange}
                handleAvatarChange={handleAvatarChange}
                handleSave={handleSave}
                handleBack={handleBack}
                user={user}
                editProfileOpen={editProfileOpen}
                handleEditProfileClose={handleEditProfileClose}
                message={message}
                showSnackbar={showSnackbar}
                handleSnackbarClose={handleSnackbarClose}

            />

        </>
    )
}

export default Profile;