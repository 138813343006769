import { useEffect, useState } from 'react';
import { Row, Col, Spin, notification, Radio, Popconfirm, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCartItems, publishProductItem, removeFromCart, updateReturnStatus } from '../../redux/actions/cartActions';
import EmptyCart from './EmptyCart';
import CartItem from './CartItem';
import * as colors from "../Color/color";
import { useAuth0 } from '@auth0/auth0-react';
import { TextField, Button, styled, useMediaQuery } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const BackButtonWrapper = styled('div')({
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
});
const Cart = () => {
    const [filter, setFilter] = useState('all');
    const cartDetails = useSelector(state => state.cart);
    const { cartItems } = cartDetails;
    const [startDateTime, setStartDateTime] = useState();
    const [endDateTime, setEndDateTime] = useState();
    const [publishState, setPublishState] = useState({});
    const { user, isLoading, getAccessTokenSilently } = useAuth0();
    const [isProductsLoading, setIsProductsLoading] = useState(false);
    const isMdUp = useMediaQuery((theme) => theme.breakpoints.up(960));
    const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const history = useNavigate();

    const handleBackClick = () => {
        history('/');
    };
    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'published', label: 'Published' },
        { value: 'unpublished', label: 'Unpublished' },
        { value: 'review', label: 'Review' },
        { value: 'Rejected', label: 'Rejected' },
        { value: 'Approved', label: 'Approved' },
    ];

    const filteredCartItems = cartItems.filter(item => {
        if (filter === 'all') return true;
        if (filter === 'published') return item.liststatus === 'published';
        if (filter === 'unpublished') return item.liststatus === 'unpublished';
        if (filter === 'review') return item.status === 'review';
        if (filter === 'Rejected') return item.status === 'Rejected';
        if (filter === 'Approved') return item.status === 'Approved';
        // Add more conditions as needed
        return false;
    }).reverse();

    const fetchCartData = async () => {
        if (!isLoading && user) {
            setIsProductsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                await dispatch(fetchCartItems(user.email, token));
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch cart items. Please try again later.',
                });
            } finally {
                setIsProductsLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchCartData();
    }, [dispatch, user, isLoading]);


    const handleReturnClick = async (id) => {

        try {
            await dispatch(updateReturnStatus(id));
            notification.success({
                message: 'Success',
                description: 'Return status updated successfully.',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update return status.',
            });
        }
    };

    const publishProduct = async (productId, message) => {
        try {
            await dispatch(publishProductItem(productId, message, endDateTime,startDateTime));
            notification.success({
                message: 'Success',
                description: 'Product publish status updated successfully',
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Error',
                description: 'Failed to update product publish status. Please try again later.',
            });
        }
    };

    const handlePublishClick = (productId, message) => {
        publishProduct(productId, message);
        setPublishState(prevState => ({
            ...prevState,
            [productId]: { showPicker: false, message: '' }
        }));
    };

    const handlePublishSetup = (productId, message) => {
        setPublishState(prevState => ({
            ...prevState,
            [productId]: { showPicker: true, message }
        }));
    };

    const handleDateTimePickerSave = (productId) => {
        handlePublishClick(productId, publishState[productId]?.message);
    };

    const removeItemFromCart = async (id) => {
        try {
            await dispatch(removeFromCart(id));
            notification.success({
                message: 'Success',
                description: 'Item removed from cart successfully',
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Error',
                description: 'Failed to remove item from cart. Please try again later.',
            });
        }
    };

    return (
        <>
            <Spin spinning={isProductsLoading}>
                <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3, py: 5, minHeight : "70vh" }}>
                    <Paper sx={{ maxWidth: 1200, margin: 'auto', marginTop : isMdUp ? "0px" : "50px", overflow: 'hidden', p: 2 }}>
                        <>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <BackButtonWrapper onClick={handleBackClick}>
                                    <ArrowLeftOutlined />
                                </BackButtonWrapper>
                                <div>
                                    <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "10px", color: "grey" }}>
                                        Back to Home
                                    </Typography>
                                    <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        My Listings
                                    </Typography>
                                </div>
                            </div>

                            <Row gutter={[16, 16]} >
                                <Col span={24}>
                                    <div style={{ padding: '15px 0px', background: '#fff', display: 'flex', justifyContent: 'space-between' }}>
                                        {/* <Typography.Title level={4} style={{ margin: 0 }}>My Listings ({filteredCartItems?.length})</Typography.Title> */}
                                        <Radio.Group value={filter} onChange={e => setFilter(e.target.value)} >
                                            {filterOptions.map(option => (
                                                <Radio.Button key={option.value} value={option.value}>{option.label}</Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    {
                                        filteredCartItems.length ?
                                            filteredCartItems.map(item => (
                                                <div key={item.id} style={{ marginBottom: 20 }}>
                                                    <CartItem item={item} removeItemFromCart={removeItemFromCart} />
                                                    <div style={{ display: 'flex', gap: isSmDown ? '10px' : '20px', flexDirection: isSmDown ? 'column' : 'row', justifyContent: 'center', padding: '16px 22px', background: '#fff', boxShadow: '0 -2px 10px 0 rgb(0 0 0 / 10%)', borderTop: '1px solid #f0f0f0' }}>
                                                        <Popconfirm
                                                            title="Are you sure to Remove?"
                                                            onConfirm={() => dispatch(removeFromCart(user.email, item.id))}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button style={{ background: colors.main_theme_color, color: "#FFF", borderRadius: 2, width: isSmDown ? '100%' : 250, height: 35 }}>
                                                                Remove
                                                            </Button>
                                                        </Popconfirm>
                                                        {publishState[item.id] && publishState[item.id].showPicker ? (
                                                            <>
                                                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                                    <DatePicker.RangePicker
                                                                        minDate={dayjs().startOf('day')}
                                                                        format="YYYY-MM-DD HH:mm:ss"
                                                                        onChange={(dates) => {
                                                                            const [start, end] = dates || [null, null];
                                                                            setStartDateTime(start);
                                                                            setEndDateTime(end);
                                                                        }}
                                                                        style={{ width: '300px' }}
                                                                    />
                                                                    <Button type="primary" onClick={() => handleDateTimePickerSave(item.id)}>
                                                                        Submit
                                                                    </Button>
                                                                    <Button onClick={() => setPublishState(prevState => ({
                                                                        ...prevState,
                                                                        [item.id]: { showPicker: false, message: '' }
                                                                    }))}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    onClick={() => publishProduct(item.id, "unpublished")}
                                                                    style={{ background: item.liststatus === "unpublished" ? "grey" : colors.main_theme_color, color: "#FFF", borderRadius: 2, width: isSmDown ? '100%' : 250, height: 35 }}
                                                                    disabled={item.liststatus === "unpublished"}

                                                                >
                                                                    UnPublish
                                                                </Button>
                                                                <Button
                                                                    onClick={() => handlePublishSetup(item.id, "published")}
                                                                    style={{ background: item.liststatus === "published" ? "grey" : colors.main_theme_color, color: "#FFF", borderRadius: 2, width: isSmDown ? '100%' : 250, height: 35 }}
                                                                    disabled={item.liststatus === "published"}
                                                                >
                                                                    Publish
                                                                </Button>
                                                                <Button onClick={() => handleReturnClick(item.id)} style={{ background: item.pickedupstatus ? colors.main_theme_color : "grey", color: "#FFF", borderRadius: 2,width: isSmDown ? '100%' : 250, height: 35 }} >
                                                                    Returned
                                                                </Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )) : <EmptyCart />
                                    }
                                </Col>
                            </Row>
                        </>

                    </Paper>
                </Box>
            </Spin>
        </>
    )
}

export default Cart;
