import React, { useState } from 'react';
import { Modal, Form, Input, Avatar, Space, Typography, message, Upload, Button } from 'antd';
import { UserOutlined, PhoneOutlined, MailOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../redux/actions/userActions';

const { Title } = Typography;

const ProfileUpdateModal = ({ visible, userData, form }) => {
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(userData?.picture || '');
  
  const handleProfileUpdate = async (formData) => {
    try {
      await dispatch(updateProfile(formData));
      message.success('Profile updated successfully!');
    } catch (error) {
      message.error('Failed to update profile');
      console.error(error);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    
    if (info.file.status === 'done') {
      try {
        const base64Image = await convertToBase64(info.file.originFileObj);
        setImageUrl(base64Image);
        form.setFieldValue('avatar', base64Image);
      } catch (error) {
        message.error('Failed to process image');
        console.error(error);
      }
    }
  };

  const uploadProps = {
    name: 'avatar',
    maxCount: 1,
    accept: 'image/*',
    showUploadList: false,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok", file);
      }, 0);
    },
    onChange: handleImageUpload
  };

  return (
    <Modal
      title={
        <Space align="center" style={{ width: '100%', justifyContent: 'center', marginBottom: 24 }}>
          <Avatar size={64} src={imageUrl} icon={<UserOutlined />} />
          <Title level={4} style={{ margin: 0 }}>Edit Profile</Title>
        </Space>
      }
      open={visible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={[
        <Button 
          key="submit" 
          type="primary" 
          onClick={() => form.submit()} 
          size="large"
          style={{ width: '120px' }}
        >
          Submit
        </Button>
      ]}
      width={520}
      centered
      bodyStyle={{ padding: '24px 32px' }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleProfileUpdate}
        initialValues={{
          firstname: userData?.given_name || '',
          lastname: userData?.family_name || '',
          email: userData?.email || '',
          avatar: userData?.picture || '',
          phonenumber: "",
        }}
        style={{ maxWidth: '100%' }}
      >
        <Space direction="horizontal" size={24} style={{ display: 'flex', width: '100%' }}>
          <Form.Item
            name="firstname"
            label="First Name"
            rules={[{ required: true, message: 'Please enter your first name' }]}
            style={{ flex: 1 }}
          >
            <Input
              prefix={<UserOutlined style={{ color: '#bfbfbf' }} />}
              placeholder="Enter your first name"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="lastname"
            label="Last Name"
            rules={[{ required: true, message: 'Please enter your last name' }]}
            style={{ flex: 1 }}
          >
            <Input
              prefix={<UserOutlined style={{ color: '#bfbfbf' }} />}
              placeholder="Enter your last name"
              size="large"
            />
          </Form.Item>
        </Space>

        <Form.Item
          name="email"
          label="Email Address"
        >
          <Input
            prefix={<MailOutlined style={{ color: '#bfbfbf' }} />}
            disabled
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            { required: true, message: 'Please enter your phone number' },
            { pattern: /^\+?[\d\s-]+$/, message: 'Please enter a valid phone number' }
          ]}
        >
          <Input
            prefix={<PhoneOutlined style={{ color: '#bfbfbf' }} />}
            placeholder="Enter your phone number"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="avatar"
          label="Profile Picture"
          style={{ marginBottom: 0 }}
        >
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '16px'
          }}>
            <Avatar 
              size={64} 
              src={imageUrl}
              icon={<UserOutlined />} 
            />
            <Upload {...uploadProps}>
              <Button 
                icon={<UploadOutlined />}
                size="large"
                style={{ height: '40px' }}
              >
                Change Picture
              </Button>
            </Upload>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProfileUpdateModal;