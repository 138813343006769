import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Page1 from '../Steppers/Page1';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled, useMediaQuery } from '@mui/material';
import Page2 from '../Steppers/Page2';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { getProductDetails } from '../../redux/actions/productActions';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from 'dayjs';
import { BASE_URL, Currency, STRIPE_PUBLIC_KEY } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Card, Col, notification, Row } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../../AxiosInsstance';

const steps = ['Select Rental Time Period', 'Review and Checkout'];
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const ResponsiveDiv = styled('div')(({ theme }) => ({
    padding: "20px 0px",
    [theme.breakpoints.down('md')]: {
        padding: "10px 0px",
    },
}));


export default function HorizontalLinearStepper() {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const { id } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const { loading, product } = useSelector(state => state.getProductDetails);
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const { isAuthenticated, user } = useAuth0();
    const [disableDateRanges, setDateRanges] = useState([]);
    const [insurance, setInsurance] = useState(false);
    const [insurancePrice, setInsurancePrice] = useState(0);
    const dispatch = useDispatch();
    const [totalPriceofItem, setTotalPriceofItem] = useState(0);
    const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    useEffect(() => {
        if (product?.id) {
            axiosInstance.get(`/dates/${product.id}`)
                .then(response => setDateRanges(response.data))
                .catch(error => {
                    notification.error({
                        message: 'Error',
                        description: `Error fetching dates: ${error.message}`,
                        placement: 'topRight',
                    });
                });
        }
    }, [product]);

    const calculatePriceWithCommission = () => {
        if (startDateTime && endDateTime && product) {
            const hoursDiff = Math.abs(endDateTime - startDateTime) / 36e5;
            const totalPrice = product.price * hoursDiff;
            setTotalPriceofItem(totalPrice);
            const commission = totalPrice * 0.05;
            const insurPrice = insurance ? totalPrice * 0.1 : 0;
            setInsurancePrice(insurPrice);
            return totalPrice + commission + insurPrice;
        }
        return 0;
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                if (product && id !== product.id) {
                    await dispatch(getProductDetails(id));
                }
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: `Error fetching product details: ${error.message}`,
                    placement: 'topRight',
                });
            }
        };

        fetchProductDetails();
    }, [dispatch, product, id, loading]);

    const makePayment = async () => {
        try {
            if (!isAuthenticated) {
                notification.error({
                    message: 'Error',
                    description: 'You are not authenticated. Please log in.',
                    placement: 'topRight',
                });
                return;
            }

            const check = await fetch(`${BASE_URL}/user/${user.email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!check.ok) {
                throw new Error(`HTTP error! status: ${check.status}`);
            }

            const item = await check.json();

            if (item.status !== 'Approved') {
                toast.error('Please verify the user before renting it.');
                return;
            }
            const stripe = await loadStripe(STRIPE_PUBLIC_KEY);

            const amount = Math.round(calculatePriceWithCommission() * 100);

            const body = {
                product: product.id,
                productby: product.productby,
                amount,
                user_email: user.email,
                startDateTime,
                endDateTime,
                transaction_type: "debit",
                insurancePrice,
                currency : Currency
            };

            const response = await axiosInstance.post('/create-checkout-session', body);

            const session = await response.data;

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                notification.error({
                    message: 'Error',
                    description: `Error during payment process: ${result.error.message}`,
                    placement: 'topRight',
                });
            }


        } catch (error) {
            notification.error({
                message: 'Error',
                description: `Error during payment process: ${error.message}`,
                placement: 'topRight',
            });
        }
    }
    const isDateRangeOverlap = (start1, end1, start2, end2) => {
        return (start1 <= end2 && end1 >= start2);
    };


    const handleNext = () => {
        if (activeStep === 0 && (!startDateTime || !endDateTime)) {
            notification.error({
                message: 'Error',
                description: 'Please set both the start and end times.',
            });
            return;
        }

        const start = dayjs(startDateTime);
        const end = dayjs(endDateTime);

        for (let range of disableDateRanges) {
            if (isDateRangeOverlap(start, end, dayjs(range.startDateTime), dayjs(range.endDateTime))) {
                notification.error({
                    message: 'Error',
                    description: 'Selected dates overlap with a restricted range. Please choose different dates.',
                });
                return;
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ width: '100%', padding: "5px", marginTop: isMdUp ? "10px" : "80px" }}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment >
                        <ResponsiveDiv>
                            {activeStep === 0 && <Page1 product={product} startDateTime={startDateTime} endDateTime={endDateTime} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} calculatePriceWithCommission={calculatePriceWithCommission} insurance={insurance} setInsurance={setInsurance} totalPriceofItem={totalPriceofItem} insurancePrice={insurancePrice} />}
                            {activeStep === 1 && <Page2 product={product} startDateTime={startDateTime} endDateTime={endDateTime} calculatePriceWithCommission={calculatePriceWithCommission} insurance={insurance} setInsurance={setInsurance} totalPriceofItem={totalPriceofItem} insurancePrice={insurancePrice} />}
                            <Card style={{ width: "100%" }}>
                                <Row justify={"space-between"}>
                                    <Col>
                                        <Button

                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            style={{ marginRight: '8px' }}
                                        >
                                            Back
                                        </Button>



                                    </Col>
                                    <Col>
                                        <Button
                                            type='primary'
                                            color="inherit"
                                            onClick={activeStep === 1 ? makePayment : handleNext}
                                            style={{padding : "24px"}}
                                        >
                                            {activeStep === steps.length - 1 ? 'PayNow' : activeStep === 1 ? 'Pay Now' : 'Go to Payment'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </ResponsiveDiv>

                    </React.Fragment>
                )}
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <ToastContainer />
        </LocalizationProvider>
    );
}
